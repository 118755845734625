import React, { useState, useEffect } from "react";
import {
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
  Typography,
  Button,
} from "@material-tailwind/react";
import { RegisterContact } from "../../API/APIS";
import { useLocation } from 'react-router-dom';

export default function ConsultationForm({ open, handleOpen }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [pincode, setPincode] = useState('');
  const [message, setMessage] = useState('');
  const [isRegister, setIsRegister] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const location = useLocation();
  const [category, setCategory] = useState('');

  useEffect(() => {
    const path = location.pathname;
    let categoryValue = 'Contact Us';

    if (path.includes('/interio')) {
      categoryValue = 'Interio';
    } else if (path.includes('/magazine')) {
      categoryValue = 'Magazine';
    } else if (path.includes('/modular')) {
      categoryValue = 'Modular';
    } else if (path.includes('/edufurn')) {
      categoryValue = 'Edufurn';
    } else if (path.includes('/medifurn')) {
      categoryValue = 'Medifurn';
    } else if (path.includes('/shopfit')) {
      categoryValue = 'Shopfit';
    }

    setCategory(categoryValue);
  }, [location.pathname]);

  const RegisterHandler = async (e) => {
    e.preventDefault();
    let data = {
      Name: name,
      Email: email,
      MobileNo: mobileNo,
      Pincode: pincode,
      Category: category,
    };
    let result = await RegisterContact(data);
    setIsRegister(result.isRegistered);
    if (result.isRegistered) {
      setEmail('');
      setMobileNo('');
      setName('');
      setMessage(result.message);
      setPincode('');
      setTimeout(() => {
        setMessage('');
      }, 3000);
      handleOpen();
      setOpenAlert(true);
    } else {
      setMessage(result.message);
    }
  };

  const handleAlertOpen = () => setOpenAlert((cur) => !cur);

  return (
    <>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="bg-transparent drop-shadow-md w-[0]"
      >
        <form onSubmit={RegisterHandler}>
          <Card className="mx-auto w-full max-w-auto">
            <CardBody className="flex flex-col gap-4">
              <Typography color={isRegister ? "green" : "red"}>
                {message}
              </Typography>
              <Input label="Name" size="lg" value={name} required onChange={(e) => { setName(e.target.value) }} />
              <Input label="Email" size="lg" required value={email} onChange={(e) => { setEmail(e.target.value) }} />
              <Input label="Number" size="lg" required value={mobileNo} maxLength={10} onChange={(e) => { setMobileNo(e.target.value) }} />
              <div className="-ml-2.5">
                <Checkbox label="Send updates on WhatsApp" color="orange" />
              </div>
              <Input label="Current residence pincode" value={pincode} size="lg" onChange={(e) => { setPincode(e.target.value) }} />
            </CardBody>
            <CardFooter className="pt-0">
              <Button fullWidth className="bg-r-gold" type="submit">
                Get FREE Quote
              </Button>
              <Typography variant="small" className="mt-6 gilroyLight">
                By submitting this form, you agree to <span className="text-r-gold">privacy policy</span> & <span className="text-r-gold">terms and conditions</span>
              </Typography>
            </CardFooter>
          </Card>
        </form>
      </Dialog>

      <Dialog
        size="sm"
        open={openAlert}
        handler={handleAlertOpen}
        className="bg-transparent drop-shadow-md"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="gray" className='gilroyBold tracking-widest text-center'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute cursor-pointer right-[10px] top-[10px]" onClick={handleAlertOpen}>
                <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
              </svg>
            </Typography>
            <Typography variant="h5" className='gilroyBold tracking-widest text-left'>
              <div className="text-r-tvoi flex">
                <div> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                </svg>
                </div>
                <div className="ml-2">
                  Your contact details have been received successfully. We'll be in touch with you soon.
                </div>
              </div>
            </Typography>
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
}
