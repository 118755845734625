export const interior ={
    Title:"Explore a variety of interior furniture design concepts curated to enhance the ambiance of homes and commercial spaces alike. From contemporary to classic styles, discover inspiration for incorporating sofas, tables, chairs, and decor accessories that elevate any room's aesthetic appeal.", 
    Tabs: [
    { 
        Name:"Living Room" ,
        Blogs:[]
    },
    { 
        Name:"Kitchen" ,
        Blogs:[]
    },
    { 
        Name:"Bed Room" ,
        Blogs:[]
    },
    { 
        Name:"Pooja Room" ,
        Blogs:[]
    },
    { 
        Name:"Dinning Area" ,
        Blogs:[]
    }]
}

export const edufurn ={ 
    Title:"Explore educational furniture design concepts crafted to create dynamic and engaging learning environments. Discover adaptable classroom layouts, collaborative workstations, and student-focused seating arrangements designed to enhance interaction, creativity, and academic performance.",
    Tabs: [
    { 
        Name:"Class Room" ,
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Computer Lab" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Kindergarden" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Labs" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Library" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Hostel" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Chairs" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Auditorium" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    },
    { 
        Name:"Management Space" ,        
        SubCategory:["Steel","Wood","Steel Plus Wood"],
        Blogs:[]
    }
]
}

export const medifurn ={ 
    Title:"Dive into a range of ergonomic and practical medical furniture designs tailored to healthcare settings. From patient rooms to examination areas, explore solutions that prioritize comfort, hygiene, and accessibility, ensuring optimal functionality for both patients and healthcare professionals.",
    Tabs: [
    { 
        Name:"Recliner Bed" ,
        Blogs:[]
    },
    { 
        Name:"Admin Office" ,        
        Blogs:[]
    },
    { 
        Name:"Labs" ,        
        Blogs:[]
    },
    { 
        Name:"Lobby" ,        
        Blogs:[]
    },
    { 
        Name:"Complementary Furnitures" , 
        Blogs:[]
    },
    { 
        Name:"Pharmacy" ,
        Blogs:[]
    },
    { 
        Name:"Transfer" ,
        Blogs:[]
    },
    { 
        Name:"Conference" ,
        Blogs:[]
    },
    { 
        Name:"Cafeteria" , 
        Blogs:[]
    },
    { 
        Name:"Consulting" , 
        Blogs:[]
    },
    { 
        Name:"Trolley" , 
        Blogs:[]
    },
]}

export const modular ={
    Title:"Delve into innovative modular furniture solutions designed to maximize functionality and adaptability in modern living and work environments. Discover space-saving designs and customizable configurations that cater to diverse spatial needs, offering flexibility and efficiency without compromising on style.",
    Tabs: [
    { 
        Name:"Front Office" ,
        Blogs:[]
    },
    { 
        Name:"Conference" ,        
        Blogs:[]
    },
    { 
        Name:"Work station" ,        
        Blogs:[]
    },
    { 
        Name:"Executive desk" ,        
        Blogs:[]
    },
    { 
        Name:"Seating" , 
        Blogs:[]
    }
]}

export const shopfit ={ 
    Title:"Discover retail-focused furniture design ideas aimed at enhancing customer experiences and optimizing store layouts. From stylish display units to functional checkout counters, explore solutions that blend aesthetics with functionality, creating inviting and efficient retail spaces for shoppers and businesses alike.",
    Tabs: [
    { 
        Name:"Jewellery Shop" ,
        Blogs:[]
    },
    { 
        Name:"Mobile Shop" ,        
        Blogs:[]
    },
    { 
        Name:"Bike Showroom" ,        
        Blogs:[]
    },
    { 
        Name:"Car Showroom" ,        
        Blogs:[]
    },
    { 
        Name:"Restaurants" , 
        Blogs:[]
    },
    { 
        Name:"Cafe" ,
        Blogs:[]
    },
    { 
        Name:"Pharmacy or Medical" ,
        Blogs:[]
    },
    { 
        Name:"Super Market" ,
        Blogs:[]
    },
    { 
        Name:"Clothing Stores" , 
        Blogs:[]
    },
    { 
        Name:"Sweet Stall" , 
        Blogs:[]
    }
]
}

